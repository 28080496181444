<template>
  <v-card
    flat
    class="pa-3 mt-1"
  >
    <v-card-title class="d-flex align-center justify-center py-0">
      <div
        class="d-flex align-center"
      >
        <v-img
          :src="require(`@/assets/images/logos/logo.png`)"
          max-width="300px"
          alt="logo"
          contain
          class="me-0 "
        ></v-img>
      </div>
    </v-card-title>
    <v-form class="multi-col-validation">
      <v-card-text class="pt-1">
        <p class="text-h6 text-center">Create a new client</p>
        <v-row>
            <v-col cols="12" md="6" class="py-0 my-1">
              <v-text-field v-model="name" ref="name" outlined dense label="Name"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0 my-1">
              <v-text-field v-model="username" ref="username" outlined dense label="User name"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0 my-1">
              <!-- current password -->
              <v-text-field v-model="userPassword" ref="userPassword" :type="isUserPasswordVisible ? 'text' : 'password'"
                :append-icon="isUserPasswordVisible ? mdiEyeOffOutline : mdiEyeOutline"
                label="Input User Password" outlined dense
                @click:append="isUserPasswordVisible = !isUserPasswordVisible"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0 my-1">
              <!--  password word-->
              <v-text-field v-model="passwordAgain" ref="passwordAgain" :type="isPasswordAgainVisible ? 'text' : 'password'"
                :append-icon="isPasswordAgainVisible ? mdiEyeOffOutline : mdiEyeOutline" label="Input Current Password"
                outlined dense @click:append="isPasswordAgainVisible = !isPasswordAgainVisible"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0 my-1">
              <v-text-field v-model="phone_number" ref="phone_number" outlined dense label="Phone number"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0 my-1">
              <v-file-input
                show-size
                v-model="id_card"
                ref="id_card"
                truncate-length="18"
                style="cursor: pointer"
                label="Select ID Card"
                accept=".jpe,.png,.jpg,.docx,.doc,.pdf"
              ></v-file-input>
            </v-col>
            <v-col cols="12" md="6" class="py-0 my-1">
              <v-text-field v-model="email" ref="email" type="email" outlined dense label="Email address"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0 my-1">
              <v-text-field v-model="profession" ref="profession" outlined dense label="Profession"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0 my-1">
              <v-text-field v-model="residence" ref="residence" outlined dense label="Residence"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0 my-1">
              <v-select v-model="language" ref="language" :items="languages" outlined dense label="Select Language"></v-select>
            </v-col>
            <v-col cols="12" md="6" class="py-0 my-1">
              <v-text-field v-model="date_of_birth" ref="date_of_birth" type="date" outlined dense label="Date of Birth"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0 my-1">
              <v-select v-model="gender" ref="gender" :items="genders" outlined dense label="Select a gender"></v-select>
            </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="text-end">
        <spinner v-if="loading" class="text-end"></spinner>
        <v-btn 
          v-else
          color="primary"
          class="me-3 mt-3"
          @click="CreateClient()"
        >
          Create Client
        </v-btn>

      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import axios from 'axios'
import Spinner from "@/components/Spinner.vue";

export default {
  components: {
    Spinner,
  },

  data() {
    return {
      loading: false,
      userPassword: '',
      isUserPasswordVisible: false,
      isPasswordAgainVisible: false,
      username: "",
      email: "",
      phone_number: "",
      passwordAgain: "",
      language: "",
      name: "",
      gender: "",
      profession: "",
      date_of_birth: "",
      residence: "",
      id_card: [],

      mdiKeyOutline,
      mdiLockOpenOutline,
      mdiEyeOffOutline,
      mdiEyeOutline,
      genders: [
        'Male',
        'Female'
      ],
      languages: [
        'French',
        'English'
      ],
    }
  },
  
  mounted(){
      // executes these after the page has been mounted
      document.title = "Manager: Savings | Create Client"
  },

  methods:{
    async CreateClient(){
      if(this.name.length < 8){
          this.$store.commit('setSnackBarMessage', "Please input user full names")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.name.focus()  // this causes a an auto fucos to the element
      }
      else if(this.username.length < 4){
              this.$store.commit('setSnackBarMessage', "User name is too short, user name needs to be at least 4 characters")
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
              this.$refs.username.focus()  // this causes a an auto fucos to the element
      }
      else if(this.userPassword === "" || this.passwordAgain === ""){
          this.$store.commit('setSnackBarMessage', "Please input password and its verification")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.userPassword.focus()  // this causes a an auto fucos to the element
      }
      else if(this.userPassword != this.passwordAgain){
          this.$store.commit('setSnackBarMessage', "Please passwords are different, verify again")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.passwordAgain.focus()  // this causes a an auto fucos to the element
      }
      else if(this.phone_number.length < 8){
          this.$store.commit('setSnackBarMessage', "Please input user phone number")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.phone_number.focus()  // this causes a an auto fucos to the element
      }
      else if(this.id_card.size > 6000000){
          this.$store.commit('setSnackBarMessage', "Please upload a file less than 5mb")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.id_card.focus()  // this causes a an auto fucos to the element
      }
      else if(this.email.length < 8){
          this.$store.commit('setSnackBarMessage', "Please input user email")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.email.focus()  // this causes a an auto fucos to the element
      }
      else if(this.profession.length == 0){
          this.$store.commit('setSnackBarMessage', "Please input client profession")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.profession.focus()  // this causes a an auto fucos to the element
      }
      else if(this.residence.length == 0){
          this.$store.commit('setSnackBarMessage', "Please input client residence")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.residence.focus()  // this causes a an auto fucos to the element
      }
      else if(this.language.length == 0){
          this.$store.commit('setSnackBarMessage', "Please select language")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.language.focus()  // this causes a an auto fucos to the element
      }
      else if(this.date_of_birth.length == 0){
          this.$store.commit('setSnackBarMessage', "Please input client date of birth")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.date_of_birth.focus()  // this causes a an auto fucos to the element
      }
      else if(this.gender.length == 0){
          this.$store.commit('setSnackBarMessage', "Please select gender")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.gender.focus()  // this causes a an auto fucos to the element
      }
      else{
        this.loading = true
  
        let formData = new FormData()
        formData.append('id_card', this.id_card)
        formData.append('password', this.password)
        formData.append('password_again', this.password_again)
        formData.append('username', this.username)
        formData.append('name', this.name)
        formData.append('phone_number', this.phone_number)
        formData.append('language', this.language)
        formData.append('gender', this.gender)
        formData.append('profession', this.profession)
        formData.append('date_of_birth', this.date_of_birth)
        formData.append('residence', this.residence)
        formData.append('email', this.email)

        await axios
            .post('/api/v1/manager/create/client/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => {
                console.log(response.data)
                this.$store.commit('setSnackBarMessage', response.data[0].text)
                this.$store.commit('setSnackBarColor', response.data[0].color)
                this.$store.commit('activateSnackBar', true)
                this.$store.commit('setSnackBarTimeOut', 8000)  // set time out for snack bar to 6seconds and remember too reset after 

                if(response.data[0].category === "success"){
                  let router = this.$router
                  setTimeout(function(){
                    router.push('/savings/clients/create')
                  }, 4000)
                }
            })
            .catch(error => {
                if (error.response){
                    this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                }else{
                    this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                }
            })

        this.loading = false
        }

    },
  },
}
</script>
